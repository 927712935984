// @flow
import * as React from 'react'

import Snap from 'icons/snap-thin.svg'
import Calendar from 'icons/calendar-outline.svg'
import Invite from 'icons/invitation.svg'
import Analytics from 'icons/pacman-bars.svg'

export const items = {
	invites: {
		text: 'Automatically centralize your bid invites from any source',
		icon: <Invite />,
	},
	calendar: {
		text:
			'Never miss a bid due date and keep your team in sync with one calendar',
		icon: <Calendar />,
	},
	manage: {
		text:
			'Easily manage your bid process: Assign bids, set follow-up dates and comment with your team',
		icon: <Snap />,
	},
	reports: {
		text:
			'Use reports and analytics to see team performance and where to spend your time',
		icon: <Analytics />,
	},
}

export const videoId = 'f20ruhdpcm'

export const title = 'Bid Board Pro'
export const subtitle =
	'Manage all your bid invites with the only online bid board for subcontractors—and win the right jobs.'
