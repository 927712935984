// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import 'components/layout/index.css'
import 'components/layout/font.css'
import ModalManager from 'components/modal-manager'

import useScreenWidth from 'hooks/use-screen-width'
import DesignA from './design-a'
import DesignB from './design-b'

const InAppPromo = () => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Div>
			<ModalManager />
			<Div id="design_a">
				<DesignA />
			</Div>
			<Div id="design_b" display="none">
				<DesignB />
			</Div>
		</Div>
	)
}

export default InAppPromo
