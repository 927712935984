// @flow
import * as React from 'react'
import { Div } from 'glamorous'

type ListItem = {
	text: string,
	icon: React.Node,
}

type Props = {
	data: Array<ListItem>,
	textColor: string,
	fontWeight: string,
	fontSize: string,
	maxWidth: string,
	additionalCss: {},
	additionalItemCss: {},
	additionalItemTextCss: {},
}

const Item = ({
	icon,
	text,
	textColor,
	fontWeight,
	fontSize,
	additionalCss,
	additionalItemTextCss,
}) => (
	<Div
		alignItems="center"
		display="flex"
		flexDirexction="column"
		css={additionalCss}
	>
		<Div width="42px" height="42" alignItems="center" justifyContent="center">
			{icon}
		</Div>
		<Div
			fontSize={fontSize}
			color={textColor}
			fontWeight={fontWeight}
			lineHeight="1.33"
			css={additionalItemTextCss}
		>
			{text}
		</Div>
	</Div>
)

const ItemList = ({
	data,
	textColor,
	fontWeight,
	fontSize,
	maxWidth,
	additionalCss,
	additionalItemCss,
	additionalItemTextCss,
}: Props) => {
	return (
		<Div
			display="flex"
			flexDirection="column"
			justifyContent="center"
			height="auto"
			css={additionalCss}
		>
			{data.map(itemData => (
				<Item
					key={itemData.text}
					{...itemData}
					textColor={textColor}
					fontWeight={fontWeight}
					fontSize={fontSize}
					maxWidth={maxWidth}
					additionalCss={additionalItemCss}
					additionalItemTextCss={additionalItemTextCss}
				/>
			))}
		</Div>
	)
}

export default ItemList
