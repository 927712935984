import React from 'react'
import { Button } from 'glamorous'

import { mobile } from 'shared/media-queries'
import { showRequestDemoModal } from '../../../components/modal-manager'
import trackEvent from 'shared/track'
import { cyan, white } from 'shared/colors'

const DemoButton = () => (
	<Button
		display="block"
		height="44px"
		lineHeight="44px"
		padding="0 45px"
		fontSize="18px"
		cursor="pointer"
		onClick={() => {
			trackEvent({
				category: 'Request Demo',
				action: 'Open modal',
				label: 'BBP In App Promo',
			})
			showRequestDemoModal()
		}}
		css={{
			backgroundColor: cyan,
			border: '0px',
			borderRadius: '2px',
			color: white,
			[mobile]: {
				width: '97%',
				margin: '0 auto',
			},
		}}
	>
		Get a demo
	</Button>
)

export default DemoButton
