// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'
import { WistiaVideoOverlay } from 'views/components'
import { navy, grayText } from 'shared/colors'
import * as Constants from './shared/constants'
import ItemList from './shared/item-list'
import { laptop, tablet, mobile } from 'shared/media-queries'
import Quote from './shared/quote'
import Spacer from '../../components/spacer'
import DemoButton from './shared/demo-button'

import image from './shared/img/wistia-a.png'
import image2x from './shared/img/wistia-a@2x.png'
import image3x from './shared/img/wistia-a@3x.png'

const TitleAndList = () => {
	const { invites, calendar, manage, reports } = Constants.items
	return (
		<Div maxWidth="457px" css={{ [tablet]: { maxWidth: '100%' } }}>
			<Div
				fontSize="54px"
				color={navy}
				fontWeight="bold"
				lineHeight="1.07"
				css={{
					[mobile]: {
						textAlign: 'center',
						fontSize: '44px',
					},
				}}
			>
				{Constants.title}
			</Div>
			<Div
				fontSize="24px"
				color={grayText}
				lineHeight="1.33"
				css={{
					[tablet]: {
						maxWidth: '530px',
						textAlign: 'center',
						margin: '0 auto',
					},
					[mobile]: {
						fontWeight: '300',
						maxWidth: '310px',
					},
				}}
			>
				{Constants.subtitle}
			</Div>
			<Spacer height={10} />
			<ItemList
				data={[invites, calendar, manage, reports]}
				textColor={navy}
				fontWeight="600"
				fontSize="18px"
				maxWidth="390px"
				additionalCss={{
					[tablet]: {
						display: 'flex',
						textAlign: 'left',
						flexDirection: 'row',
						flexWrap: 'wrap',
						maxWidth: '650px',
						justifyContent: 'space-around',
						margin: '0 auto',
					},
				}}
				additionalItemCss={{
					marginTop: '33px',
					maxWidth: '450px',
					[tablet]: {
						flexDirection: 'column',
						textAlign: 'center',
						maxWidth: '300px',
					},
				}}
				additionalItemTextCss={{
					marginLeft: '30px',
					[tablet]: {
						marginLeft: '0px',
						maxWidth: '300px',
						paddingTop: '10px',
					},
					[mobile]: {
						flex: '0 0 auto',
						maxWidth: '244px',
						marginLeft: '0px',
					},
				}}
			/>
			<Spacer height={64} />
		</Div>
	)
}

const VideoQuoteDemo = () => (
	<Div flex="0 0 325px">
		<Div
			position="relative"
			display="block"
			width="325px"
			marginBottom="61px"
			css={{
				[tablet]: {
					justifyContent: 'center',
					margin: '0 auto',
					width: '242px',
				},
				[mobile]: {
					width: '200px',
				},
			}}
		>
			<WistiaVideoOverlay videoId={Constants.videoId} />
			<Img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} width="100%" />
		</Div>
		<Div display="flex">
			<Div
				display="flex"
				flex="0 0 323px"
				css={{
					[laptop]: {
						flex: '0 0 323px',
					},
					[tablet]: {
						flex: '0 0 300px',
					},
				}}
			>
				<Quote />
			</Div>
		</Div>
		<Spacer height={50} />
		<Div
			display="flex"
			flex="0 0 323px"
			justifyContent="flex-start"
			css={{
				[tablet]: {
					justifyContent: 'center',
					flex: '0 0 300px',
					marginLeft: '0px',
				},
			}}
		>
			<DemoButton />
		</Div>
	</Div>
)

const DesignA = () => (
	<Div
		display="flex"
		paddingTop="100px"
		justifyContent="center"
		css={{ [tablet]: { flexDirection: 'column', textAlign: 'center' } }}
	>
		<TitleAndList />
		<Spacer width={150} />
		<VideoQuoteDemo />
	</Div>
)

export default DesignA
