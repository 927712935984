// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'
import { tablet } from 'shared/media-queries'
import Spacer from 'components/spacer'
import { navy } from 'shared/colors'
import kevin from './img/kevin.png'
import HangingQuote from 'views/components/hanging-quote'

const Quote = () => (
	<Div color={navy} css={{ [tablet]: { display: 'none' } }}>
		<HangingQuote>
			On average, I’d say Bid Board Pro saves us at least a full work day a
			week.
		</HangingQuote>
		<Spacer height="15px" />
		<Div display="flex">
			<Img height="40px" width="40px" src={kevin} />
			<Spacer width="14px" />
			<Div display="flex" flex="0 0 200px" flexDirection="column">
				<Div fontSize="16px" fontWeight="bold">
					Kevin Frederik
				</Div>
				<Div fontSize="14px">
					VP of Business Development, Innovative Mechanical
				</Div>
			</Div>
		</Div>
	</Div>
)

export default Quote
