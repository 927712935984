// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'
import { WistiaVideoOverlay } from 'views/components'
import { navy, grayText, white } from 'shared/colors'
import * as Constants from './shared/constants'
import Spacer from '../../components/spacer'
import ItemList from './shared/item-list'
import { laptop, tablet, mobile } from 'shared/media-queries'
import Quote from './shared/quote'
import DemoButton from './shared/demo-button'

import image from './shared/img/wistia-b.png'
import image2x from './shared/img/wistia-b@2x.png'
import image3x from './shared/img/wistia-b@3x.png'

const Header = () => (
	<Div
		backgroundColor={navy}
		display="flex"
		justifyContent="center"
		alignItems="center"
		width="100vw"
		padding="45px"
		css={{
			[tablet]: {
				paddingTop: '79px',
				paddingBottom: '70px',
				textAlign: 'center',
			},
			[mobile]: {
				paddingTop: '88px',
			},
		}}
	>
		<Div>
			<Div
				fontSize="54px"
				color={white}
				fontWeight="bold"
				lineHeight="1.07"
				css={{
					[tablet]: {
						textAlign: 'left',
					},
					[mobile]: {
						textAlign: 'center',
						fontSize: '44px',
					},
				}}
			>
				{Constants.title}
			</Div>
			<Div
				css={{
					[tablet]: {
						display: 'flex',
						textAlign: 'left',
					},
					[mobile]: {
						display: 'flex',
						textAlign: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					},
				}}
			>
				<Div
					fontSize="24px"
					color={white}
					fontWeight="300"
					lineHeight="1.33"
					maxWidth="528px"
					marginRight="130px"
					css={{
						[tablet]: {
							maxWidth: '313px',
							marginRight: '52px',
						},
						[mobile]: {
							marginRight: '0px',
						},
					}}
				>
					{Constants.subtitle}
				</Div>
				{/* <Spacer width={140} /> */}
				<Div
					position="relative"
					display="none"
					css={{
						[tablet]: {
							display: 'block',
							width: '200px',
						},
						[mobile]: {
							marginTop: '26px',
							marginBottom: '35px',
						},
					}}
				>
					<WistiaVideoOverlay videoId={Constants.videoId} />
					<Img
						width="100%"
						src={image}
						srcSet={`${image2x} 2x, ${image3x} 3x`}
					/>
				</Div>
			</Div>
		</Div>
		<Div
			position="relative"
			css={{
				[tablet]: {
					display: 'none',
				},
			}}
		>
			<WistiaVideoOverlay videoId={Constants.videoId} />
			<Img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} />
		</Div>
	</Div>
)

const ListQuoteAndDemo = () => {
	const { invites, calendar, manage, reports } = Constants.items
	return (
		<Div
			display="flex"
			justifyContent="center"
			paddingTop="85px"
			css={{
				[tablet]: {
					display: 'flex',
					flexDirection: 'column',
					margin: '0 auto',
					alignItems: 'center',
				},
				[mobile]: {
					display: 'block',
				},
			}}
		>
			<Div maxWidth="560px">
				<ItemList
					data={[invites, calendar, manage, reports]}
					textColor={grayText}
					fontWeight="300"
					fontSize="22px"
					maxWidth="460px"
					additionalCss={{
						[tablet]: { maxWidth: '100%' },
					}}
					additionalItemCss={{
						':not(:first-child)': {
							marginTop: '33px',
						},
						[tablet]: {},
						[mobile]: {
							flexDirection: 'column',
							textAlign: 'center',
						},
					}}
					additionalItemTextCss={{
						marginLeft: '30px',
						flex: '0 0 460px',
						[mobile]: {
							flex: '0 0 auto',
							maxWidth: '300px',
							paddingTop: '10px',
							marginLeft: '0px',
						},
					}}
				/>
			</Div>
			<Spacer width={120} />
			<Div display="flex" flexDirection="column" flex="0 0 323px">
				<Div
					css={{
						[laptop]: {
							flex: '0 0 250px',
						},
						[mobile]: {
							display: 'none',
						},
					}}
				>
					<Quote />
					<Spacer height={50} />
					<DemoButton />
				</Div>
			</Div>
			<Div display="none" css={{ [mobile]: { display: 'block' } }}>
				<Spacer height={50} />
			</Div>
		</Div>
	)
}

const DesignB = () => (
	<Div display="flex" flexDirection="column">
		<Header />
		<ListQuoteAndDemo />
		<Div
			display="none"
			css={{
				[mobile]: {
					justifyContent: 'center',
					display: 'flex',
					flex: '0 0 300px',
					marginLeft: '0px',
				},
			}}
		>
			<DemoButton />
		</Div>
	</Div>
)

export default DesignB
